<template>
    <pop-up background-color="rgba(0, 0, 0, 0.5)" width="900px" cardColor="#ffffff">
        <div class="main">
            <div class="title">免責聲明Disclaimer</div>
            <div class="disclaimer" v-if="disclaimer">
                <div class="content-text" v-html="disclaimer"></div>
            </div>
            <div class="content-text" v-else>
                親愛的醫療照護專業人員您好，<br />
                為更快速地與您交流本公司醫學相關資訊，在您提供去識別化之案例資訊後，<br />
                表示您同意台灣羅氏大藥廠股份有限公司(以下簡稱“羅氏”)為交流疾病、醫學、藥品等醫學新知或教育目的(以下簡稱
                “目的”)而(1)允許其他獲准加入本社交軟體群組之醫療照護專業人員閱讀上述案例資訊，或(2)進行與上述目的有關的資訊蒐集、分析、統計、管理或使用(以下統稱”使用”)。
                台灣羅氏或台灣羅氏所委託之第三人將遵守台灣個人資料保護法等個人資料保護相關法規，<br />
                並承諾僅於上述目的與法律許可範圍內蒐集、分析、統計、管理、或使用您提供的去識別化案例資料。
                前述案例資料之使用，不僅限於台灣地區內，亦沒有期間限制。請確認上述資訊正確無誤，也請您確認所提供案例之個人資料皆已去識別化。<br />
                <br />
                因為填寫個案涉及到病人個人資訊，請再次確認填寫前已告知當事人，並經過當事人同意<br />
            </div>
            <button-wrapper type="navigation" color="primary" @click="onConfirm">
                我瞭解了
            </button-wrapper>
        </div>
    </pop-up>
</template>

<script>
import PopUp from '@/components/Popup/PopUpWrapper.vue';
import { mapActions, mapMutations } from 'vuex';

export default {
    components: { PopUp },
    data() {
        return {
            disclaimer: ''
        };
    },
    created() {
        this.getViewDisclaimer();
    },
    methods: {
        ...mapMutations({
            updateDisclaimerPop: 'popup/updateDisclaimerPop'
        }),
        ...mapActions({
            apiClient: 'api/invoke'
        }),
        onConfirm() {
            this.updateDisclaimerPop(false);
        },
        async getViewDisclaimer() {
            const PAYLOAD = {
                category: 'ViewDisclaimer',
                function: 'fetch',
                hideMessage: true
            };
            let result = await this.apiClient(PAYLOAD);
            this.disclaimer = result.data.content;
        }
    }
};
</script>

<style lang="scss" scoped>
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 640px) {
        margin-top: 55px;
        margin-bottom: 40px;
    }
    .title {
        color: #000000;
        font-size: 20px;
        text-align: center;
        margin-bottom: 25px;
    }

    .disclaimer {
        height: 60vh;
        margin-bottom: 20px;
        width: 95%;
        overflow: auto;

        @media screen and (min-width: 640px) {
            padding: 60px;
        }
    }

    .content-text {
        white-space: normal;
        font-size: 16px;
        word-break: break-all;
    }
}
</style>
