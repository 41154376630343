<template>
    <div class="doctor-index index-page" :class="$route.params.disease">
        <NavigationBar />
        <router-view />
        <transition name="fade" ease="in-out">
            <Disclaimer v-if="disclaimerPop" />
        </transition>
        <transition name="fade" ease="in-out">
            <AEDisclaimer v-if="aeDisclaimerPopUp" />
        </transition>
        <transition name="fade" ease="in-out">
            <RevealInformation v-if="revealInformationPopup" />
        </transition>
    </div>
</template>

<script>
import NavigationBar from '@/components/Navigation/NavigationBar.vue';
import AEDisclaimer from '@/components/Popup/AEDisclaimer.vue';
import Disclaimer from '@/components/Popup/Disclaimer.vue';
import RevealInformation from '@/components/Popup/RevealInformation.vue';
import Store from '@/store/index';
import { mapState } from 'vuex';

export default {
    components: { NavigationBar, Disclaimer, AEDisclaimer, RevealInformation },
    computed: {
        ...mapState({
            disclaimerPop: state => state.popup.disclaimerPop,
            aeDisclaimerPopUp: state => state.popup.aeDisclaimerPopUp,
            revealInformationPopup: state => state.popup.revealInformationPopup
        })
    },
    async beforeRouteEnter(to, from, next) {
        const TOKEN = window.localStorage.getItem('doctor-token');
        const DISEASE = window.location.pathname.split('/')[1];
        const ID = window.location.pathname.split('/')[3];
        Store.commit('api/setDisease', DISEASE);
        if (TOKEN) {
            Store.commit('api/setToken', TOKEN);
            const PAYLOAD = {
                category: 'Auth',
                function: 'caseTokenVerify',
                successMsg: '已登入',
                data: ID
            };
            const RES = await Store.dispatch('api/invoke', PAYLOAD);
            if (!RES) {
                window.localStorage.removeItem('doctor-token');
                window.location.href = `${window.location.origin}/${DISEASE}/doctor/${ID}/login`;
                window.history.replaceState(
                    {},
                    '',
                    `${window.location.origin}/${DISEASE}/doctor/${ID}/login`
                );
            } else {
                Store.commit('api/setToken', RES.data.token);
                await Store.dispatch('doctor/setState', ID);
                if (to.name === 'Doctor_Login') {
                    next(vm => {
                        vm.$router.push({ name: 'Doctor_Intro' });
                    });
                } else next();
            }
        } else if (to.name === 'Doctor_Login') next();
        else {
            window.location.href = `${window.location.origin}/${DISEASE}/doctor/${ID}/login`;
            window.history.replaceState(
                {},
                '',
                `${window.location.origin}/${DISEASE}/doctor/${ID}/login`
            );
        }
    }
};
</script>
